import gql from "graphql-tag";
import features from "@/config/features";

const ignoredPropertiesEnabled = gql`
  fragment IgnoredPropertiesFields on SettingsMutationResult {
    ignoredProperties
    deviceLock
  }
`;

const ignoredPropertiesDisabled = gql`
  fragment IgnoredPropertiesFields on SettingsMutationResult {
    __typename
  }
`;

export const ignoredProperties = features.stateManagement ? ignoredPropertiesEnabled : ignoredPropertiesDisabled;
